<sz-tool-bar *ngIf="!isGps" [buttons]="toolBarButtons" />
<div id="split-view-container">
    <app-chart-split-view
        class="h-full w-full"
        [isLoading]="isLoading"
        [charts]="charts"
        [isSplitView]="isSplitView"
        [messages]="messages"
        [deviceNames]="deviceNames"
        [showToolbar]="!isGps"
        (deleteChart)="deleteChart($event)"
        (saveChart)="loadAllCharts()"
    />
</div>
