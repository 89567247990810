import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../../services/app-material/app-material.module';
import { DataFileComponent } from './data-file.component';
import { ChartCardModule } from 'src/app/chart/chart-card/chart-card.module';
import { ChartSplitViewModule } from 'src/app/chart/chart-split-view/chart-split-view.module';
import { CommonModule } from 'src/app/common/common.module';

export { DataFileComponent };

@NgModule({
    imports: [
        AppMaterialModule,
        ChartCardModule,
        ChartSplitViewModule,
        CommonModule,
    ],
    exports: [DataFileComponent],
    declarations: [DataFileComponent],
})
export class DataFileModule {}
