import { Component, Input, OnInit } from '@angular/core';
import { chartPageMixin } from 'src/app/chart/chart-page.mixin';
import {
    CalculatedSignal,
    Chart,
    ChartType,
    SignalRawData,
} from 'src/app/chart/chart.model';
import { ToolBarButton } from 'src/app/common/layout/tool-bar/tool-bar-button.model';
import { toolBarPageMixin } from 'src/app/common/layout/tool-bar/tool-bar-page.mixin';
import { ApiService } from 'src/app/services/api/api.service';
import { waitForItem } from 'src/app/services/api/cached-api';
import { CsvExportService } from 'src/app/services/csv-export/csv-export.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { log } from 'src/app/shared/log';
import { DataFile } from 'src/app/shared/models/data-file';
import { ExpressionNull } from 'src/app/shared/models/expression/null';

const _DataFileComponentBase = chartPageMixin(toolBarPageMixin());
@Component({
    selector: 'app-data-file',
    templateUrl: './data-file.component.html',
    styleUrls: ['./data-file.component.scss'],
})
export class DataFileComponent
    extends _DataFileComponentBase
    implements OnInit
{
    @Input() id;

    dataFile: DataFile;
    mapData;
    chartType = ChartType;

    get isGps() {
        return this.dataFile !== undefined && this.dataFile.type === 'gps';
    }

    get deviceNames() {
        const names = new Map();
        if (this.dataFile) {
            names.set(this.dataFile.deviceId, this.dataFile.deviceIdentifier);
        }
        return names;
    }

    constructor(
        public csvExportService: CsvExportService,
        private api: ApiService,
        private snackBar: SnackBarService,
    ) {
        super();
    }

    async ngOnInit() {
        this.setToolBarButtons();
        await this.load();
    }

    setToolBarButtons() {
        // Set header buttons and callbacks
        this.clearToolBar();
        this.addToolBarButtons([
            new ToolBarButton({
                id: 'splitView',
                icons: ['unfold-more-horizontal', 'unfold-less-horizontal'],
                tooltips: ['reports.toggle_split_view'],
                callback: this.toggleSplitView.bind(this),
                disabled: true,
            }),
            new ToolBarButton({
                icons: ['plus'],
                tooltips: ['log_charts.add_chart'],
                callback: this.addNewChart.bind(this),
            }),
        ]);
    }

    updateToolBarButtons() {
        this.updateToolBar('splitView', 'disabled', this.charts.length < 2);
    }

    async load() {
        // We need to use better LOADING indicators in the future.
        this.snackBar.open('loading');
        try {
            await waitForItem(this.api.dataFiles, this.id);
            this.dataFile = this.api.dataFiles.get(this.id);
            this.messages = await this.api.dataFiles.getMessages(this.id);

            if (this.dataFile.type == 'log') {
                this.addNewChart();
            } else if (this.dataFile.type == 'gps') {
                this.addGpsChart(await this.api.dataFiles.getGpsData(this.id));
            }
        } catch (err) {
            this.messages = [];
            log.error(err);
        }
    }

    addGpsChart(points) {
        const chart = new Chart(
            '#' + (this.charts.length + 1),
            ChartType.MAP,
            [new CalculatedSignal('', [], new ExpressionNull())],
            this.readValues.bind(this),
        );
        chart.id = this.id;
        this.addChart(chart);
        chart.chartPoints = (points ?? []).map(({ timestamp, lat, lon }) => [
            timestamp,
            lat,
            lon,
        ]);
    }

    loadAllCharts() {
        this.charts.forEach((c) => c.load());
    }

    async readValues(chart: Chart) {
        const labels: string[] = [];
        const nameCounts: Map<string, number> = new Map();
        for (const signal of chart.calculatedSignals) {
            if (!nameCounts.has(signal.name)) {
                nameCounts.set(signal.name, 0);
            }
            const duplicateIndex = nameCounts.get(signal.name);
            nameCounts.set(signal.name, duplicateIndex + 1);
            labels.push(chart.signalName(signal, duplicateIndex));
        }
        const signalIds = chart.allSignals(this.messages).map((s) => s.id);
        let dataSequences: SignalRawData[] = [];
        if (signalIds.length > 0) {
            const data = await this.api.historicalData.getByDataFile(chart.id, {
                signalIds,
            });
            dataSequences = data.map((series) => ({
                deviceId: +this.dataFile.deviceId,
                signalId: +series.id,
                values: new Map(series.values ?? []),
            }));
        }
        await chart.setData(dataSequences, labels, null);
    }
}
